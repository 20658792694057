import axios from 'axios';

// qn_1007_调研老人总数
export const dylrzs = (data) => axios.get('/service-data-service/api/openService/ky/dylrzs', data);

// qn_1008_服务需求比例
export const fwxqbl = (data) => axios.get('/service-data-service/api/openService/ky/fwxqbl', data);

// qn_1011_服务需求分析
export const fwxqfx = (data) => axios.get('/service-data-service/api/openService/ky/fwxqfx', data);



// qn_1009_调研老人总数_地图
export const dylrzsdt = (data) => axios.get('/service-data-service/api/openService/ky/dylrzs/dt', data);

