import axios from 'axios';

//qn_年龄性别分析
export const nlxbfx = (data) => axios.get('/service-data-service/api/openService/ky/nlxbfx', data);
//qn_1004_居住情况分析
export const jzqkfx = (data) => axios.get('/service-data-service/api/openService/ky/jzqkfx', data);
//qn_1002_慢病分析
export const mbfx = (data) => axios.get('/service-data-service/api/openService/ky/mbfx', data);
//qn_1003_失能分析
export const snfx = (data) => axios.get('/service-data-service/api/openService/ky/snfx', data);

//qn_1005_经济情况分析_经济来源
export const jjqkfx_jjly = (data) => axios.get('/service-data-service/api/openService/ky/jjqkfx/jjly', data);
//qn_1005_经济情况分析_收入占比
export const jjqkfx_srzb = (data) => axios.get('/service-data-service/api/openService/ky/jjqkfx/srzb', data);

//qn_1006_经济情况分析_支出项目
export const jjqkfx_zcxm = (data) => axios.get('/service-data-service/api/openService/ky/jjqkfx/zcxm', data);
//qn_1006_经济情况分析_支出占比
export const jjqkfx_zczb = (data) => axios.get('/service-data-service/api/openService/ky/jjqkfx/zczb', data);




