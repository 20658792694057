import axios from 'axios';

// qn_1022_适老化改造_占比
export const slhgz_zb = (data) => axios.get('/service-data-service/api/openService/ky/slhgz/zb', data);
// qn_1023_适老化改造_居住情况
export const slhgz_jzqk = (data) => axios.get('/service-data-service/api/openService/ky/slhgz/jzqk', data);
// qn_1024_适老化改造_失能情况
export const slhgz_snqk = (data) => axios.get('/service-data-service/api/openService/ky/slhgz/snqk', data);


// qn_1021_助医服务_慢病
export const zyfw_mb = (data) => axios.get('/service-data-service/api/openService/ky/zyfw/mb', data);
// qn_1020_助医服务_需求人数和占比
export const zyfw_xqzb = (data) => axios.get('/service-data-service/api/openService/ky/zyfw/xqzb', data);



// qn_1018_助餐服务_用餐困难
export const zcfw_yckn = (data) => axios.get('/service-data-service/api/openService/ky/zcfw/yckn', data);
// qn_1018_助餐服务_需求人数和占比
export const zcfw_xqrshzb = (data) => axios.get('/service-data-service/api/openService/ky/zcfw/xqrshzb', data);


//qn_1015_上门照护服务_需求占比
export const smzhfw_zb = (data) => axios.get('/service-data-service/api/openService/ky/smzhfw/zb', data);
//qn_1016_上门照护服务_居住情况
export const smzhfw_jzqk = (data) => axios.get('/service-data-service/api/openService/ky/smzhfw/jzqk', data);
//qn_1017_上门照护服务_服务内容
export const smzhfw_fwnr = (data) => axios.get('/service-data-service/api/openService/ky/smzhfw/fwnr', data);



//qn_1012_院前急救_需求人数和占比
export const yqjj_xqzb = (data) => axios.get('/service-data-service/api/openService/ky/yqjj/xqzb', data);
//qn_1014_院前急救_期望服务费
export const yqjj_qwfwf = (data) => axios.get('/service-data-service/api/openService/ky/yqjj/qwfwf', data);
//qn_1013_院前急救_设备选择
export const yqjj_sbxz = (data) => axios.get('/service-data-service/api/openService/ky/yqjj/sbxz', data);



