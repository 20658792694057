
<template>
  <div class="map-right d-flex flex-column">
    <div class="titlebox">
      <p class="title-text">
        居家服务需求
      </p>
    </div>

    <div class="modules-box mb" style="height: 21.5%" >
      <div class="header">
        <div class="left"></div>
        <div class="text">院前急救</div>
        <div class="right"></div>
      </div>
      <div class="content d-flex flex-column">
        <p class="f14 ml15">需要该服务老年人 <font class="c-y">{{yqjj_xqzbData.num||0}}人</font> 占比 <font class="c-y">{{yqjj_xqzbData.percentage||0}}%</font></p>
        <div class="d-flex h100">
          <div style="width:50%">
            <MyEchart :option="option1"/> 
          </div>
          <div style="width:50%">
            <MyEchart :option="option2"/> 
          </div>

        </div>
      </div>
    </div>

    <div class="modules-box mb" style="height: 23%;"  >
      <div class="header">
        <div class="left"></div>
        <div class="text">上门照护服务</div>
        <div class="right"></div>
      </div>
      <div class="content d-flex flex-column">
        <p class="f14 ml15">需要该服务老年人 <font class="c-y">{{smzhfw_zbData.num||0}}人</font> 占比 <font class="c-y">{{smzhfw_zbData.percentage||0}}%</font></p>
        <div class="d-flex h100">
          <div style="width:50%">
            <MyEchart :option="option3" autoTooltip/> 

          </div>
          <div style="width:50%">
            <MyEchart :option="option4" autoTooltip/> 


          </div>
        </div>

      </div>
    </div>

    <div class=" mb flex-fill d-flex " >

      <div class="modules-box mr" style="height: 100%;" >
        <div class="header">
          <div class="left"></div>
          <div class="text">助餐服务</div>
          <div class="right"></div>
        </div>
        <div class="content d-flex flex-column">
          <p class="f14 ml15">需要该服务老年人 <font class="c-y">{{zcfw_xqrshzbData.num||0}}人</font> 占比 <font class="c-y">{{zcfw_xqrshzbData.percentage||0}}%</font></p>
          <MyEchart :option="option5" autoTooltip />


        </div>
      </div>

      <div class="modules-box " style="height: 100%;" >
        <div class="header">
          <div class="left"></div>
          <div class="text">助医服务</div>
          <div class="right"></div>
        </div>
        <div class="content d-flex flex-column ">
        <p class="f14 ml15">需要该服务老年人 <font class="c-y">{{zyfw_xqzbData.num||0}}人</font> 占比 <font class="c-y">{{zyfw_xqzbData.percentage||0}}%</font></p>
          <img class="imgbg" src="@/assets/images/bottom@2x.png" alt="">
          <MyEchart :option="option6" />
        </div>
      </div>

    </div>


    <div class="modules-box " style="height: 23%;" >
      <div class="header">
        <div class="left"></div>
        <div class="text">适老化改造服务</div>
        <div class="right"></div>
      </div>
      <div class="content d-flex flex-column">
        <p class="f14 ml15">需要该服务老年人 <font class="c-y">{{slhgz_zbData.num||0}}人</font> 占比 <font class="c-y">{{slhgz_zbData.percentage||0}}%</font></p>
        <div class="d-flex h100">
          <div style="width:50%">
            <MyEchart :option="option7" autoTooltip/> 

          </div>
          <div style="width:50%">
            <MyEchart :option="option8" autoTooltip/> 


          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
slhgz_zb,
slhgz_jzqk,
slhgz_snqk,


zyfw_xqzb,
zyfw_mb,

zcfw_xqrshzb,
zcfw_yckn,

smzhfw_zb,
smzhfw_jzqk,
smzhfw_fwnr,


yqjj_xqzb,
yqjj_qwfwf,
yqjj_sbxz,




} from '@/api/home/right.js';
export default {
  name: '',
  components: {

  },
  data() {
    return {
      slhgz_zbData:{
        num: 0,percentage: 0
      },      
      zyfw_xqzbData:{
        num: 0,percentage: 0
      },      
      zcfw_xqrshzbData:{
        num: 0,percentage: 0
      },      
      smzhfw_zbData:{
        num: 0,percentage: 0
      },      
      yqjj_xqzbData:{
        num: 0,percentage: 0
      },   
      
      option1Data:{
        indicator:[
          { name: '摄像头', max: 50 },
          { name: '其他', max: 50 },
          { name: '随身感应卡', max: 50 },
          { name: '无线体征感知设备\n(没有录音录像功能)', max: 50 },
          { name: '智能音箱', max: 50 },
        ],
        seriesdata:[],
        max:50,
      },
      option2Data:{
        indicator:[
          { name: '800元以下', max: 50 },
          { name: '800-1500元', max: 50 },
          { name: '1500-3000元', max: 50 },
          { name: '3000元以上', max: 50 },
          { name: '其他', max: 50 },
        ],
        seriesdata:[],
        max:50,
      },



      option3Data:[
        { value: 0, name: '独自居住' },
        { value: 0, name: '只和老伴' },
        { value: 0, name: '和家人' },
        { value: 0, name: '其他' },
      ],
      option4Data:[
        { value: 0, name: '生活照料' },
        { value: 0, name: '协助上下楼\n供给爬楼机' },
        { value: 0, name: '康复护理' },
        { value: 0, name: '翻身扣背' },
        { value: 0, name: '入户助医' },
        { value: 0, name: '紧急呼叫' },
        { value: 0, name: '其他' },
      ],


      option5Data:[],
      option6Data:{
        yAxisdata:[],
        seriesdata:[]
      },
      option7Data:[],
      option8Data:[],

      
    }
  },
  watch: {
    area(){
      this.getData()
    },

  },
  computed: {
    area(){
      return this.$store.state.activateArea
    },
    option1(){
      let option = {
        color: ['#04FFC1'],
        title: {
          text: '设备选择',
          left: '4%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },

        radar: {
          shape: 'polygon', // 雷达图形状，可以是'circle'（圆形）或'polygon'（多边形）
          center: ['50%', '50%'], // 雷达图中心点相对容器的位置，这里是居中显示
          radius: '55%', // 雷达图半径相对于容器大小的比例
          axisName: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#01FAFF00'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#01FAFF'
            }
          },
          // indicator: [
          //   { name: '摄像头', max: 50 },
          //   { name: '其他', max: 50 },
          //   { name: '随身感应卡', max: 50 },
          //   { name: '无线体征感知设备\n(没有录音录像功能)', max: 50 },
          //   { name: '智能音箱', max: 50 },
          // ],
          indicator:this.option1Data.indicator,
          
        },
        series: [
          {
            name: '院前急救',
            type: 'radar',
            symbol: 'none', 
            data: [
              {
                value: this.option1Data.seriesdata,
                // value: [500, 35000, 35000, 35000, 35000],
                name: '设备选择',
                areaStyle: {
                  color: { x: 0, y: 0, x2: 0, y2: 1,
                    type: 'linear',
                    global: false,
                    colorStops: [
                      {
                        offset: 0, // 0%处的颜色
                        color: '#FFDAB57B',
                      },
                      {
                        offset: 1, // 100%处的颜色
                        color: '#04FFC1',
                      },
                    ],
                  },
                }


              },
            ]
          }
        ]


      };
      return option

    },
    option2(){
      let option = {
        color: ['#0492FF'],
        title: {
          text: '期望服务费',
          left: '1%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },
        radar: {
          shape: 'polygon', // 雷达图形状，可以是'circle'（圆形）或'polygon'（多边形）
          center: ['50%', '50%'], // 雷达图中心点相对容器的位置，这里是居中显示
          radius: '55%', // 雷达图半径相对于容器大小的比例
          axisName: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#01FAFF00'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#01FAFF'
            }
          },

          // indicator: [
          //   { name: '800元以下', max: 50000 },
          //   { name: '800-1500元', max: 50000 },
          //   { name: '1500-3000元', max: 50000 },
          //   { name: '3000元以上', max: 50000 },
          //   { name: '其他', max: 50000 },
          // ],
          indicator:this.option2Data.indicator



        },
        series: [
          {
            name: '院前急救',
            type: 'radar',
            symbol: 'none', 
            data: [
              {
                // value: [35000, 35000, 35000, 35000, 35000],
                value: this.option2Data.seriesdata,
                name: '期望服务费',
                areaStyle: {
                  color: { x: 0, y: 0, x2: 0, y2: 1,
                    type: 'linear',
                    global: false,
                    colorStops: [
                      {
                        offset: 0, // 0%处的颜色
                        color: '#BE7BDA',
                      },
                      {
                        offset: 1, // 100%处的颜色
                        color: '#0492FF',
                      },
                    ],
                  },

                }


              },
            ]
          }
        ]


      };
      return option

    },
    option3() {

      
      let seriesData = this.option3Data
      // [
      //   { value: 148, name: '独自居住' },
      //   { value: 735, name: '只和老伴' },
      //   { value: 580, name: '和家人' },
      //   { value: 484, name: '其他' },
      // ]
      let total = seriesData.reduce((a, b) => {
        return a + b.value * 1
      }, 0)
      let option = {
        title: {
          text: '居住情况',
          left: '4%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },


        color: ['#FCA78A', '#DFAE48', '#83B697', '#F38C30'],

        tooltip: {
          ...this.$store.state.echart.tooltip,
          trigger: 'item',
          position:'right',
          formatter: function (params) {
            let percent = params.percent == undefined ? 0 : params.percent
            var res = params.marker + params.name + '：' + percent + '%'
            return res
          },
        },
        legend: {
          orient: 'vertical',
          icon: 'circle',
          right: '8%',
          top: 'center',
          itemGap: this.$common.sizeAdjust(16),
          formatter: (name) => {
            const item = seriesData.filter((item) => item.name === name)[0]
            let percent =
              total == 0 ? 0 : ((item.value / total) * 100).toFixed(2)
            return `{a|${name}} {b| ${item.value}}`
          },
          textStyle: {
            // 添加
            padding: [0, 0, 0, 0],
            rich: {
              a: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                width: this.$common.sizeAdjust(100),
                color: '#fff',
              },
              b: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                color: '#fff ',
              },
            },
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            center: ['25%', '50%'],
            radius: ['30%', '50%'],
            label: {
              show: false,
            },
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      return option
    },
    option4() {

      
      let seriesData =this.option4Data
      //  [
      //   { value:0, name: '生活照料' },
      //   { value:0, name: '协助上下楼\n供给爬楼机' },
      //   { value:0, name: '康复护理' },
      //   { value:0, name: '翻身扣背' },
      //   { value:0, name: '入户助医' },
      //   { value:0, name: '紧急呼叫' },
      //   { value:0, name: '其他' },
      // ]
      
      let total = seriesData.reduce((a, b) => {
        return a + b.value * 1
      }, 0)
      let option = {
        title: {
          text: '服务内容',
          left: '4%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },


        color: ['#7CAACF', '#FDA546', '#FFDF6F', '#D1CFA9', '#83B697', '#5DBC93', '#61CDC2'],

        tooltip: {
          ...this.$store.state.echart.tooltip,
          trigger: 'item',
    
          formatter: function (params) {
            let percent = params.percent == undefined ? 0 : params.percent
            var res = params.marker + params.name + '：' + percent + '%'
            return res
          },
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          icon: 'circle',
          right: '8%',
          top: 'center',
          itemGap: this.$common.sizeAdjust(20),
          formatter: (name) => {
            const item = seriesData.filter((item) => item.name === name)[0]
            let percent =
              total == 0 ? 0 : ((item.value / total) * 100).toFixed(2)
            return `{a|${name}} {b| ${item.value}}`
          },
          textStyle: {
            // 添加
            padding: [0, 0, 0, 0],
            rich: {
              a: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                width: this.$common.sizeAdjust(120),
                color: '#fff',
              },
              b: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                color: '#fff ',
              },
            },
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            center: ['25%', '50%'],
            radius: ['30%', '50%'],
            label: {
              show: false,
            },
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      return option
    },
    option5() {
      return {
        title: {
          text: '用餐困难', 
          left: '1%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },
        tooltip: {
          ...this.$store.state.echart.tooltip,
          formatter: '{b} : {c}人',
        },
        grid: {...this.$store.state.echart.grid,  right: '10%',top: '15%',bottom: '8%'},

        xAxis: {
          axisLabel:this.$store.state.echart.xAxis.axisLabel,
          minInterval: 1,
          splitLine: {
            show: false
          },
        },
        yAxis: {
          data: ['有但是取餐难送餐贵', '有但是价格贵', '没有养老助餐点', '做饭难', '买菜难','没有困难'],
          
          ...this.$store.state.echart.yAxis,
          type: 'category',
        
          splitLine: {
            show: false,
            
          },
          axisTick: {
            show: false, 
            
          },

        },
        
        series: [
          {
            name: '人数',
            type: 'bar',
            data:this.option5Data,
            // data: [ 23, 25, 76, 135, 56 , 56 ],
            itemStyle: {
              borderRadius: [0, 20, 20, 0],
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0,
                [
                  {offset: 0, color: '#00E3C060'},
                  {offset: 1, color: '#0068DC90'},
                ]
              )
            },

          },
        ],
      }
    },    
    option6() {


      
      let yData = this.option6Data.seriesdata
      // [300, 498, 778, 382, 
      //  299, 372,300, 498, 778, 382, 299
      // ]
      let barWidth = 20;
      let option = {
        title: {
          text: '慢病情况', 
          left: '1%', 
          top: 'top', 
          
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },

        dataZoom: [
          {
            type: 'slider', // 缩放条类型为滑动条
            show: true, // 显示缩放条
            startValue: 0, // 从头开始。
            endValue: 4, // 一次性展示几个。
            bottom: 10, // 距离底部的距离
            height: 10, // 设置高度
          },
        ],



        
        grid: {
          left: '10%',
          right: '5%',
          top: '20%',
          bottom: '3%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'item',
          formatter: function (parms) {
            return parms.marker + ' ' + parms.name + '：' + parms.value + '人'
          },
        },
        xAxis: {
          type: 'category', // category(坐标轴类型)
          data:this.option6Data.yAxisdata,
          //  ['健康', '糖尿病', '高血压', '冠心病', 
          // '心脏病', '脑血栓' , '甲状腺' , '风湿骨病' , '皮肤病' , '失智' , '其他'
          // ],
          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            show: false,
            lineStyle: {
              
              // 坐标轴轴线样式
              color: 'rgba(255,255,255,0.15)', // 坐标轴轴线颜色
            },
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 10,
            margin: this.$common.sizeAdjust(42),
            rotate: 45,
          },
        },
        yAxis: {
          type: 'value', // value(数值轴,适用于连续数据)
          minInterval: 1,
          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            show: false, // 是否显示坐标轴轴线
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#9AD2EA',
            fontSize: 14,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: 'pictorialBar', // pictorialBar(象形柱图)
            // label: {
            //   // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
            //   show: true, //是否显示标签
            //   position: 'insideTop', // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
            //   color: '#01E4FF',
            //   fontSize: 14,
            // },
            symbolSize: [barWidth,barWidth/2.5], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [0, barWidth/5], // 图形相对于原本位置的偏移
            z: 30, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: {
              color:'#0B99F6'
              // // 图形样式
              // // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   // 这里 offset: 0 1 ,表示从下往上的渐变色
              //   {
              //     offset: 0, // 0%处的颜色
              //     color: 'rgba(31,155,255,1)',
              //   },
              //   {
              //     offset: 1, // 100%处的颜色
              //     color: 'rgba(0,229,255,1)',
              //   },
              // ]),
            },
            data: yData,
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: 'bar', // 柱状图
            barWidth: barWidth, // 柱条的宽度,不设时自适应
            barGap: '0%', // 柱子与柱子之间的距离
            itemStyle: {
              // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: { x: 0, y: 0, x2: 0, y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0, // 0%处的颜色
                    color: '#265193',
                  },
                  {
                    offset: 1, // 100%处的颜色
                    color: '#03B1FA',
                  },
                ],
              },
            },
            data: yData,
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            type: 'pictorialBar',
            symbolSize: [barWidth, barWidth/2],
            symbolOffset: [0, -barWidth/3],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color:'#229EE0',
            },
            data: yData,
          },
        ],
      }
      return option
    },
    option7(){
      let option = {
        title: {
          text: '居住情况',
          left: '3%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },

        },
        tooltip: {
          ...this.$store.state.echart.tooltip,
          formatter: '{b} : {c}人',
        },
        grid: {...this.$store.state.echart.grid ,top: '20%',left: '5%', bottom: '3%',},
        xAxis: {
          type: 'category',
          axisLabel:{rotate: 45,color:'#fff',},
          axisLine:{
            lineStyle: {
              width: this.$common.sizeAdjust(5), 
              color: '#27565C', // 自定义轴线颜色
            },
          },
          axisTick: {
            lineStyle: {
              color: '#00ff0000', // 自定义刻度线颜色
            },
          },      
          data: ['独自居住', '只和老伴', '和家人', '其他']
        },
        yAxis: {
          ...this.$store.state.echart.yAxis,
          type: 'value',
          splitLine: {
            show: false
          }          
        },
        series: [
          {
            data: this.option7Data,//[120, 200, 150, 80],
            type: 'pictorialBar', // 象形柱图
            symbol: 'triangle', // 三角形
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {offset: 0, color: '#04DDCB'}, 
                {offset: 0.9, color: '#04DDCB00'}
              ]
            },
          }
        ]
      };
      return option

    },
    option8(){
      let option = {
        title: {
          text: '失能情况',
          left: '1%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },

        },
        tooltip: {
          ...this.$store.state.echart.tooltip,
          formatter: '{b} : {c}人',
        },
        grid: {...this.$store.state.echart.grid ,top: '20%',left: '5%', bottom: '3%',},
        xAxis: {
          type: 'category',
          axisLabel:{rotate: 45,color:'#fff',},
          axisLine:{
            lineStyle: {
              width: this.$common.sizeAdjust(5), 
              color: '#27565C', // 自定义轴线颜色
            },
          },
          axisTick: {
            lineStyle: {
              color: '#00ff0000', // 自定义刻度线颜色
            },
          },          
          data: ['残疾', '轻度', '中度', '重度', '活力老人']
          
        },
        yAxis: {
          ...this.$store.state.echart.yAxis,
          type: 'value',
   
          splitLine: {
            show: false
          } 
        },
        series: [
          {
            data: this.option8Data,// [120, 200, 150, 80, 70],
            type: 'pictorialBar', // 象形柱图
            symbol: 'triangle', // 三角形
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {offset: 0, color: '#1E76BB'}, 
                {offset: 0.9, color: '#1E76BB00'}
              ]
            },
          }
        ]
      };
      return option

    },
  },
  mounted() {
    this.getData()
  },
  //是当 keepalive 包含的组件再次渲染的时候触发
  activated() {},
  methods: {
    getData(){
      slhgz_zb({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.slhgz_zbData = res.data.records[0]|| {}
      })

      slhgz_jzqk({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records[0] || {}
        this.option7Data = [_data.dzjz||0,_data.hlb,_data.hjr||0,_data.qt||0]
      })


      slhgz_snqk({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records[0] || {}
        this.option8Data = [
        _data.cj||0,
        _data.qdsn||0,
        _data.zdsn||0,
        _data.zhdsn||0,
        _data.hllr||0,
        ]
      })

      zyfw_xqzb({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.zyfw_xqzbData = res.data.records[0]|| {}
      })


      zcfw_yckn({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records[0] || {}

        this.option5Data = [
        _data.mykn||0,
        _data.mcn||0,
        _data.zfn||0,
        _data.myzc||0,
        _data.scfg||0,
        _data.zcfg||0,
        ]
      })

      zyfw_mb({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let data = this.$common.bigDataDispose(res)
        let yAxisdata = []
        let seriesdata = []
        for (const iterator of data) {
          yAxisdata.push(iterator.name)
          seriesdata.push(iterator.value)
        }
        this.option6Data={
          yAxisdata,
          seriesdata
        }

      })



      zcfw_xqrshzb({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.zcfw_xqrshzbData = res.data.records[0]|| {}
      })

      smzhfw_zb({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.smzhfw_zbData = res.data.records[0]|| {}
      })


      smzhfw_jzqk({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let data = this.$common.bigDataDispose(res)
        for (const iterator of data) {
          iterator.name = iterator.name.replace('一起住','')
        }
        this.option3Data = data
      })


      smzhfw_fwnr({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let data = this.$common.bigDataDispose(res)
        for (const iterator of data) {
          iterator.name = iterator.name.replace('或','\n')
        }
        this.option4Data = data
      })


      yqjj_xqzb({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.yqjj_xqzbData = res.data.records[0]|| {}
      })



      yqjj_sbxz({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let data = this.$common.bigDataDispose(res)


        let seriesdata = []
        seriesdata = data.map(item => item.value)    
        let max = Math.max.apply(null, seriesdata)
        max = Number(max * 1.2)
        let indicator = []
        indicator = data.map(item => {
          return {
            name: item.name,
            max: max,
          }
        })
        this.option1Data={
          indicator,seriesdata,max
        }
   
      })

      yqjj_qwfwf({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let data = this.$common.bigDataDispose(res)


        let seriesdata = []
        seriesdata = data.map(item => item.value)    
        let max = Math.max.apply(null, seriesdata)
        max = Number(max * 1.2)
        let indicator = []
        indicator = data.map(item => {
          return {
            name: item.name,
            max: max,
          }
        })
        this.option2Data={
          indicator,seriesdata,max
        }

      })







    },

  },
}
</script>
<style scoped lang="scss">
.map-right {
  width: 28%;
  overflow: hidden;


  .imgbg{
    position: absolute;
    height: 3rem;
    width: 80%;
    left: 3.8rem;
    bottom: 3.5rem;
  }

}
</style>