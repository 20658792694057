<!-- 需求地图 -->
<template>
  <div class="demand-map">
    <map-left />

    <div class="map-content align-items-center">
      <div class="title">
        数智康养需求地图
      </div>
      <div class="numerical d-flex justify-content-around ">
        <div>调研总数<span>{{dylrzsData||0}}人</span></div>
        <div>服务需求比例<span>{{fwxqblData||0}}%</span></div>
      </div>
      <div class="opselect d-flex">
        <span :class="{activate:map_type==item.label}"  @click="map_type=item.label"
          v-for="(item,index) in dict.map_type" :key="index" >{{item.label}}</span>
      </div>

      <div class="map">
        <MyEchart :option="option0" isOnClick @click="clickMap" autoTooltip/>
      </div>

      <div class="modules-box map-bottom" style="height: 29%;" >
        <div class="header">
          <div class="left"></div>
          <div class="text">服务需求分析</div>
          <div class="right"></div>
        </div>
        <div class="content ">
          <MyEchart :option="option1" autoTooltip/>
        </div>
      </div>



    </div>

    <map-right />

  </div>
</template>

<script>
import mapLeft from './map-left.vue'
import mapRight from './map-right.vue'
import * as dict from '@/utils/dict.js'
import {dylrzs,fwxqbl,fwxqfx,dylrzsdt} from '@/api/home/index.js';

export default {
  name: '',
  components: {
    mapLeft,
    mapRight,
  },
  data() {
    return {
      dict:dict,
      map_type:'调研总数',
      mapDetail:{},
      mapData:[],
      dylrzsData:null,
      fwxqblData:null,

      option1Data:{
        yAxisdata:[],
        seriesdata:[],
      },


    }
  },
  watch: {
    area(){
      this.getData()
    },

  },
  computed: {
    area(){
      return this.$store.state.activateArea
    },
    options(){
      return  this.$store.state.options
    },

    option0(){
      let countyObj = {
        '旌阳区':'jingyang',
        '经开区':'jingkai',
        '什邡市':'shifang',
        '广汉市':'guanghan',
        '绵竹市':'mianzhu',
        '罗江区':'luojiang',
        '中江县':'zhongjiang',
      }
      // let datajson = getJsonData(this.region)
      let datajson = require('@/components/echarts/geoMap.json')
      this.$echarts.registerMap('deyang', datajson)
      // let arr  = []
      // this.mapData.forEach(e=>{
      //   // let obj = Object.values(Number('3000.00'))
      //   let obj = [e.lng,e.lat,Number(e.count)]
      //   arr.push(obj)
      // })
      // var data = arr
      var data = [
        // [104.68,30.11, 2600],
        // [105.36,30.10, 2600],
        // [105.02,30.28, 2700],
      ]
      let areaData = [];
      data.map(item => { //扩大热力图效果
        areaData.push(...new Array(10).fill(item))
      })
      // console.log(data);
      // console.log(areaData);
      let mapMax = Math.max(...data.map(item => item[2]))
      let mapMin = Math.min(...data.map(item => item[2]))
      let layoutSize = '80%'
      let roam = 'scale' //是否开启鼠标缩放和平移漫游。默认不开启。开启缩放'scale'，开启平移'move'。设置成 true 为都开启
      return {
        animation: true, //多个图层缩放同步
        visualMap: {
          type: 'piecewise', // 分段型。
          // show: data.length ? true : false,
          show: false,
          bottom: 190,
          left: 200,
          color: ['#E82F1E', '#FFFF00', '#06D465'],
          // color: ['#06D465'],
          min:mapMin, //0,
          max: mapMax,
          calculable: true,
          textStyle: {
            color: '#fff',
            fontSize: '2.4rem'
          }
        },
        tooltip: {
          trigger: 'item',
        },
        geo: [
          //底层
          {
            show: true,
            map: 'deyang',
            center: undefined,
            // selectedMode: 'single',
            zoom: 1,
            scaleLimit:{ 
              min:0.8,                          //最小的缩放值
              max:3,                            //最大的缩放值
            },
            layoutCenter: ['49%', '52%'],//距离左右，上下距离的百分比
            layoutSize: layoutSize,//map百分比大小
            roam: roam,//是否开启鼠标缩放和平移漫游。默认不开启。开启缩放'scale'，开启平移'move'。设置成 true 为都开启
            silent: true, //禁用地图的hover事件
            label: {
                normal: {
                  show: false, // 省份名展示
                  color: '#FFF',
                },
                emphasis: {
                  show: false
                }
            },
            itemStyle: {
              normal: {
                areaColor: '#12A6CD25',
                borderColor: '#00F3FE25',
                borderWidth: 2,
                shadowColor: '#0E81DF',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 0,
              }
            }
          },
          // 中间层
          {
          show: true,
          map: 'deyang',
          center: undefined,
          // selectedMode: 'single',
          zoom: 1,
          scaleLimit:{ 
            min:0.8,                          //最小的缩放值
            max:3,                            //最大的缩放值
          },
          layoutCenter: ['49.5%', '51%'],//距离左右，上下距离的百分比
          layoutSize: layoutSize,//map百分比大小
          roam: roam,//是否开启鼠标缩放和平移漫游。默认不开启。开启缩放'scale'，开启平移'move'。设置成 true 为都开启
          silent: true, //禁用地图的hover事件
          label: {
            normal: {
              show: false, // 省份名展示
              color: '#FFF',
            },
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#12A6CD90',
              borderColor: '#00F3FE60',
              borderWidth: 2,
              shadowColor: '#0E81DF',
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 0,
            }
          }
          },
          //表层
          {

            // selectedMode: 'single', // 选中模式，表示是否支持多个选中，默认关闭，支持布尔值和字符串，字符串取值可选'single'表示单选，或者'multiple'表示多选。 
            // // 选中状态下的多边形和标签样式。
            // select: {
            //   disabled: false, // 是否可以被选中。在开启selectedMode的时候有效，可以用于关闭部分数据。
            //   // 选中区域文本标签
            //   label: { 
            //       color: '#ff00ff',
            //   },
            //   // 高亮状态下图形样式
            //   itemStyle: {
            //       areaColor: '#67c23a', // 高亮区域的颜色。
            //       borderColor: 'rgba(0, 0, 0,1)', // 图形的描边颜色。
            //       borderWidth: 4, // 描边线宽。为 0 时无描边。
            //       borderType: 'dashed', // 描边类型。
            //   },
            // },

            tooltip: {
              trigger: 'item',
              // padding:0,
              backgroundColor:'rgba(3,41,55,0.8)',
              borderColor: '#61F4F3', // 边框颜色
              borderWidth: 2, // 边框宽度              
              borderRadius: 10,              
              formatter: (e) => {
                var rowData  = this.mapData.find(item => item.alabel == e.name)
                if (!rowData) {
                  rowData = {}
                }
           
            
                let html
                if (this.map_type == '服务需求人数') {
                  
  
                  html = `
                  <div class="emaptip">
                    <div class="maphoverUp"></div>
                    <div class="maphoverDown"></div>
                    <div class="item">
                      <div class="title" >
                        ${e.name}
                      </div>
                      <div class="content" >
                        <p >
                          <span>服务需求人数</span>
                          <span>${rowData.xqrs||0}</span>
                        </p>
                        <p >
                          <span>占比</span>
                          <span>${rowData.xqzb||0}%</span>
                        </p>
                      </div>
                    </div>

                  </div>
                `
                }else{
              
                 html = `
                  <div class="emaptip">
                    <div class="maphoverUp"></div>
                    <div class="maphoverDown"></div>

                    <div class="item">
                      <div class="title" >
                        ${e.name}
                      </div>
                      <div class="content" >
                        <p >
                          <span>调研总数</span>
                          <span>${rowData.num||0}</span>
                        </p>
                      </div>
                    </div>

                    <div class="item">
                      <div class="title" >
                        失能情况
                      </div>
                      <div class="content" >
                        <p >
                          <span>轻度失能</span>
                          <span>${rowData.qdsn||0}</span>
                        </p>
                        <p >
                          <span>中度失能</span>
                          <span>${rowData.zdsn||0}</span>
                        </p>
                        <p >
                          <span>重度失能</span>
                          <span>${rowData.zhdsn||0}</span>
                        </p>
                      </div>
                    </div>

                    <div class="item">
                      <div class="title" >
                        居住情况
                      </div>
                      <div class="content" >
                        <p >
                          <span>独自居住</span>
                          <span>${rowData.dzjz||0}</span>
                        </p>
                        <p >
                          <span>只和老伴</span>
                          <span>${rowData.hlb||0}</span>
                        </p>
                        <p >
                          <span>和家人</span>
                          <span>${rowData.hjr||0}</span>
                        </p>
                      </div>
                    </div>


                  </div>
                ` 
                 }
                return html
              },

            },
            roam: roam,//是否开启鼠标缩放和平移漫游。默认不开启。开启缩放'scale'，开启平移'move'。设置成 true 为都开启
            map: 'deyang',
            center: undefined,
            scaleLimit:{ 
              min:0.8,                          //最小的缩放值
              max:3,                            //最大的缩放值
            },
            layoutCenter: ['50%', '50%'],//距离左右，上下距离的百分比
            layoutSize:layoutSize,//map百分比大小
            // zoom: 0.8,
            label: {
              normal: {
                show: true, // 省份名展示
                color: '#FFF',
                fontSize:'1.4rem',
                textShadowColor: "#00000050", // 文字本身的阴影颜色。
                textShadowBlur: 4, // 文字本身的阴影长度。
                textShadowOffsetX: 0, // 文字本身的阴影 X 偏移。
                textShadowOffsetY: 6, // 文字本身的阴影 Y 偏移。
              },
              emphasis: {
                show: true, // hover 省份名展示
                color: '#FFF',
                fontSize:'1.4rem',
              },
            },
            itemStyle: {
              normal: {
                areaColor: '#12A6CD',
                borderColor: '#00F3FE',
                shadowColor: 'rgba(10,29,60,.1)',
                borderWidth: 2,
                shadowBlur: 10,
                offsetX: -35,
                offsetY: 5,
              },
              emphasis:{
                areaColor: '#1A44BD',  
              }
            },
            
          }],

        series: [{
          name: 'heatmap',
          type: 'heatmap',
          coordinateSystem: 'geo',
          
          blurSize: 80,
          data: areaData
        }]
      }

    },

    option1() {
      return {
        title: {
          text: '单位：人', 
          right: '1%', 
          top: 'top', 
          textStyle: {
            color: '#fff', 
            fontSize: this.$common.sizeAdjust(25),
          
          },
        },
        tooltip: {
          ...this.$store.state.echart.tooltip,
          formatter: '{b} : {c}人',
   
 
        },
        grid: {  left: '6%', right: '6%',top: '5%',bottom: '8%', containLabel: true},

        xAxis: {
          axisLabel:this.$store.state.echart.xAxis.axisLabel,
          splitLine: {
            show: false
          },
        },
        yAxis: {
          // data: ['院前急救', '助餐服务', '上门照护服务', '助医服务', '适老化改造服务'],
          data:this.option1Data.yAxisdata,

          ...this.$store.state.echart.yAxis,
          axisLabel:{color:'#fff', fontSize: this.$common.sizeAdjust(30), padding: [0, 20, 0, 0]},
          type: 'category',
        
          splitLine: {
            show: false,
            
          },
          axisTick: {
            show: false, 
            
          },

        },
        
        series: [
          {
 
            barWidth:this.$common.sizeAdjust(30),
            type: 'bar',

            // data: [ 23, 25, 76, 135, 56 ],
            data:this.option1Data.seriesdata,

            label: {
              show: true,
              position: 'outside', // 或 'insideTop', 'insideBottom', 'outside', 'inside'
              formatter: '{c}', // 显示原始数值，也可以自定义格式化字符串
              color: '#72FFF7' ,// 文本颜色
              fontSize: this.$common.sizeAdjust(30),
              fontWeight: 'bold',
            },

            itemStyle: {
              borderRadius: [0, 30, 30, 0],
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0,
                [
                  {offset: 0, color: '#3FFEF4'},
                  {offset: 1, color: '#01C1FF'},
                ]
              )
            },

          },
        ],
      }
    },

  },
  mounted() {
   this.getData()
  },
  //是当 keepalive 包含的组件再次渲染的时候触发
  activated() {},
  methods: {
    clickMap(){

    },
    getData(){
      dylrzs({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.dylrzsData = res.data.records[0].num || 0
      })
      fwxqbl({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        this.fwxqblData = res.data.records[0].num || 0
      })

      fwxqfx({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records
        let yAxisdata = []
        let seriesdata = []
        for (const iterator of _data) {
          yAxisdata.push(iterator.title)
          seriesdata.push(iterator.num)
        }
        this.option1Data = {
          yAxisdata,
          seriesdata
        }

      })
      dylrzsdt({
        params:{
          pageNum:1,
          pageSize:500,
        }
      }).then(res => {

        let data = res.data.records
        for (const iterator of data) {
          iterator.alabel = this.options.find(item => item.value == iterator.area).label
        }
        this.mapData = data
      })



    },


  },
}
</script>
<style scoped lang="scss">
.demand-map {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem 2rem 2rem;
  position: relative;
  display: flex;


  .map-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    position: relative;

    > div.title {
      width: 38rem;
      height: 4.4rem;
      background-image: url(~@/assets/images/titlez@2x.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      line-height: 4.2rem;
      font-size: 2.2rem;
      letter-spacing: 0.1em;
      text-align: center;
      font-style: italic;
    }
    .numerical{
      width: 100%;
      
      position:absolute;
      left: 0;
      top: 6rem;
      div{
        padding: 0 2rem;
        height: 4rem;
        line-height: 4rem;
        border-radius: 5rem;
        border: 0.1rem solid #19F7FF;     
        color: #76D2ED;   
        font-size: 2rem;
        box-shadow: 0px 0px 10px #19F7FF;
        //写个循环动画 呼吸灯类似的 box-shadow
        animation: breath 1s infinite alternate;
        @keyframes breath {
          0% {
            box-shadow: 0px 0px 6px #19F7FF;
          }
          100% {
            box-shadow: 0px 0px 10px #19F7FF;
          }
        }

        font-weight: 500;
        span{
          font-weight: 600;
          position: relative;
          top: 0.2rem;
          margin-left: 1rem;
          color: #fff;
          font-size: 2.5rem;
          line-height: 2.5rem;
          font-style: italic;
        }
      }

    }
    .opselect{
      position:absolute;
      right: 2rem;
      top: 12rem;
      z-index: 99;
      span{
        cursor: pointer;
        padding: 0.6rem;
        width: auto;
        border: 0.1rem solid rgba(22,237,255,0.5);
        letter-spacing: 0.3em;
      }
      span:nth-child(1){
        border-right: none;  
      }
      span:nth-child(2){
        border-left: none;  
      }
      .activate{
        border: 0.1rem solid rgba(22,237,255,0.5);
        background: rgba(22,237,255,0.5);
      }
    }


    .map {
      flex: 1;
      width: 100%;
    }
    .map-bottom {
      width: 100%;
      height: 18rem;
    }
  }


  ::v-deep .emaptip{
    position: relative;
    color: #fff;
 
    width: 20rem;
    border-radius: 0.8rem;

    .maphoverUp{
      position: absolute;
      top: -3.7rem;
      left: -3.5rem;
      background:  url("~@/assets/images/maphover上@2x.png") no-repeat;
      background-size:cover;
      width: 136%;
      height: 3rem 

    }
    .maphoverDown{
      position: absolute;
      bottom: -2.8rem;
      left: -3.5rem;
      background:  url("~@/assets/images/maphover下@2x.png") no-repeat ;
      background-size:cover;
      width: 136%;
      height: 4rem 
    }
    .item{
      margin-bottom: 1rem;
      border-bottom: 0.2rem solid #61f4f498;
      margin-bottom: 0.8rem;
      padding-bottom: 0.8rem;
      &:last-child{
        border:none;
        margin-bottom: 0;
        padding-bottom: 0;

      }

    }



    .title{
      font-weight: 600;
      color: #4DEAEE;
      font-size: 1.6rem;
      margin-bottom: 1rem;

  
    }
    p{
      display: flex;
      justify-content:space-between;
      margin-bottom: 0.5rem;
      span{
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: #fff;
      }


    }



  }


}
</style>