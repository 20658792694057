//标准字典 可用 valueToLabel
export function valueToLabel(dict, value) {
  const item = dict.find(item => item.value == value);
  return item ? item.label : "";
}
//标准字典 可用 valueToitem
export function valueToItem(dict, value) {
  const item = dict.find(item => item.value == value);
  return item ? item : "";
}
//经济情况分析 类型
export const economics_type=[
  {label:'收入',value: 1},
  {label:'支出',value: 2},
]
//经济情况分析 类型
export const map_type=[
  {label:'调研总数',value: 1},
  {label:'服务需求人数',value: 2},
]
