
<template>
  <div class="map-left d-flex flex-column">




    <div class="titlebox">
      <p class="title-text">
        人口康养普查
      </p>
    </div>

    <div class="modules-box mb" style="height: 18%;">
      <div class="header">
        <div class="left"></div>
        <div class="text">年龄性别分析</div>
        <div class="right"></div>
      </div>
      <div class="content ">
        
        <div class="d-flex h100">
          <div style="width:80%">
           <MyEchart :option="option1" autoTooltip />
          </div>
          <div style="width:20%">
    
            <div style="color:#FFB830;font-size: 1.8rem; padding: 0.5rem 0;" class="d-flex align-items-center">
              <div class="imgbox">
                <img src="@/assets/images/女描边@2x.png" alt="">
                <div class="filler" :style="'height: '+option1Data.girlSumPercent+'%;'">
                  <img src="@/assets/images/女填充@2x.png" alt="">
                </div>
              </div>
              <font>女{{option1Data.girlSumPercent||0}}%</font>
            </div>
            <div style="color:#41A4FB;font-size: 1.8rem; padding: 0.5rem 0;" class="d-flex align-items-center">
              <div class="imgbox">
                <img src="@/assets/images/男描边@2x.png" alt="">
                <div class="filler" :style="'height: '+option1Data.manSumPercent+'%;'">
                  <img  src="@/assets/images/男填充@2x.png" alt="">
                </div>
              </div>
              <font>男{{option1Data.manSumPercent||0}}%</font>
            </div>

            
          </div>

        </div>


      </div>
    </div>
    <div class="modules-box mb" style="height: 22%;">
      <div class="header">
        <div class="left"></div>
        <div class="text">慢病分析</div>
        <div class="right"></div>
      </div>
      <div class="content">
        <img class="imgbg" src="@/assets/images/bottom@2x.png" alt="">
        <MyEchart :option="option2"  autoTooltip/>
      </div>
    </div>

    <div class=" mb flex-fill d-flex ">

      <div class="modules-box mr" style="height: 100%;">
        <div class="header">
          <div class="left"></div>
          <div class="text">失能分析</div>
          <div class="right"></div>
        </div>
        <div class="content ">
          <MyEchart :option="option3" autoTooltip/>
        </div>
      </div>

      <div class="modules-box " style="height: 100%;">
        <div class="header">
          <div class="left"></div>
          <div class="text">居住情况分析</div>
          <div class="right"></div>
        </div>
        <div class="content ">
          <MyEchart :option="option4" autoTooltip/>
        </div>
      </div>

    </div>

    <div class="modules-box " style="height: 29%;">
      <div class="header">
        <div class="left"></div>
        <div class="text">经济情况分析</div>
        <div class="right"></div>

        <div class="opselect d-flex">
          <span :class="{activate:economics_activate==item.label}"  @click="economics_activate=item.label;jjqkfx()"
          v-for="(item,index) in dict.economics_type" :key="index" >{{item.label}}</span>
        </div>


      </div>
      <div class="content d-flex flex-column">
        <div class="d-flex h100">
          <div style="width:50%">
            <MyEchart :option="option5"/> 
          </div>
          <div style="width:50%">
            <MyEchart :option="option6"/> 
          </div>

        </div>


      </div>
    </div>

  </div>

</template>

<script>
import * as dict from '@/utils/dict.js'
import {nlxbfx,jzqkfx,snfx,mbfx,
jjqkfx_jjly,
jjqkfx_srzb,
jjqkfx_zcxm,
jjqkfx_zczb,
} from '@/api/home/left.js';
export default {
  name: '',
  components: {},
  data() {
    return {
      dict:dict,
      economics_activate:'收入',

      option1Data:{
        yAxisdata:[],
        series1Data:[],
        series2Data:[],
        max:50,
        manSumPercent:50,
        girlSumPercent:50,        
      },

      option3Data:[],
      option4Data:[],

      option2Data:{
        yAxisdata:[],
        seriesdata:[],
      },
      option5Data:{
        indicator:[
          { name: '个人储蓄', max: 50 },
          { name: '其他', max: 50 },
          { name: '投资收益', max: 50 },
          { name: '养老保险', max: 50 },
          { name: '退休后第二职业', max: 50 },
          { name: '政府补贴', max: 50 },
          { name: '子女供给', max: 50 },
          { name: '退休金', max: 50 },

        ],
        seriesdata:[],
      },
      option6Data:{
        indicator:[
            { name: '1000元及以下', max: 50 },
            { name: '8001元及以上', max: 50 },
            { name: '7001至\n8000元', max: 50 },
            { name: '6001至\n7000元', max: 50 },
            { name: '5001至\n6000元', max: 50 },
            { name: '4001至\n5000元', max: 50 },
            { name: '3001至\n4000元', max: 50 },
            { name: '2001至\n3000元', max: 50 },
            { name: '1001至\n2000元', max: 50 },

        ],
        seriesdata:[],
        max:50,
      },

    }
  },
  watch: {
    area(){
      this.getData()
    },
  },
  computed: {
    area(){
      return this.$store.state.activateArea
    },
    option1() {
      let option = {
      tooltip: {
        ...this.$store.state.echart.tooltip,
        trigger: 'axis',
        
        formatter: (params) => {
          if (!params.length) return ''
          let s = params[0].axisValueLabel + '<br/>'
          for (const iterator of params) {
            // 如果是负数则反转
            let d = iterator.data < 0 ? -iterator.data : iterator.data
            s += iterator.marker + iterator.seriesName + '：' + d  + '人<br/>'
          }
          return s
        }
      },


        grid: {
          top: '10%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            max:this.option1Data.max,
            min:-this.option1Data.max,
            type: 'value',
            axisLabel:{...this.$store.state.echart.xAxis.axisLabel,
              formatter: (value) => {
                // 负数取反 显示的就是正数了
                if (value < 0) return -value
                else return value
              }
            },
            splitLine: {
              show: false
            },
          }
        ],
        yAxis: [
          {
           
            type: 'category',
            axisTick: {
              show: false
            },
            data: this.option1Data.yAxisdata,
            // data: ['65岁以下', '65-75岁', '76-85岁', '86-95岁', '95岁以上'],
            axisLabel:this.$store.state.echart.yAxis.axisLabel,
            axisLine:{
              lineStyle: {
                width: this.$common.sizeAdjust(3), 
                color: '#27565C', // 自定义轴线颜色
              },
            },


          }
        ],
        series: [

          {
            
            name: '女',
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              borderRadius: [0, 5, 5, 0],
              color: { x: 1, y: 0, x2: 0, y2: 0,
                type: 'linear',
                global: false,
                colorStops: [
                  { offset: 0,  color: '#FFD369'},
                  {  offset: 1,   color: '#DA9A00'},
                ],
              },
            },

            data: this.option1Data.series2Data,
            // data: [100, 20, 341, 30, 20]
          },
          {
            name: '男',
            type: 'bar',
            stack: 'Total',

            itemStyle: {
              borderRadius: [5, 0, 0, 5],
              color: { x: 1, y: 0, x2: 0, y2: 0,
                type: 'linear',
                global: false,
                colorStops: [
                  { offset: 0,  color: '#0072E6'},
                  {  offset: 1,   color: '#4DADFF'},
                ],
              },
            },
            
            // data: [-100, -20, -101, -30, -20,]
            data: this.option1Data.series1Data,

          }
        ]
      };
      return option
    },
    option2() {
      // let yData = [300, 498, 778, 382, 299, 372,300, 498, 778, 382, 299]
      let yData = this.option2Data.seriesdata
      let barWidth = 20;
      let option = {
        grid: {
          left: '5%',
          right: '5%',
          top: '10%',
          bottom: '3%',
          containLabel: true,
        },
        tooltip: {
          ...this.$store.state.echart.tooltip,
          trigger: 'item',
          formatter: function (parms) {
            return parms.marker + ' ' + parms.name + '：' + parms.value + '人'
          },
        },
        xAxis: {
          type: 'category', // category(坐标轴类型)
          // data: ['健康', '糖尿病', '高血压', '冠心病', '心脏病', '脑血栓' , '甲状腺' , '风湿骨病' , '皮肤病' , '失智' , '其他'],
          data:this.option2Data.yAxisdata,

          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            show: false,
            lineStyle: {
              
              // 坐标轴轴线样式
              color: 'rgba(255,255,255,0.15)', // 坐标轴轴线颜色
            },
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 10,
            margin: this.$common.sizeAdjust(42),
            rotate: 45,
          },
        },
        yAxis: {
          type: 'value', // value(数值轴,适用于连续数据)
          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },
          axisLine: {
            // 坐标轴轴线相关配置
            show: false, // 是否显示坐标轴轴线
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: '#9AD2EA',
            fontSize: this.$common.sizeAdjust(30),
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: 'pictorialBar', // pictorialBar(象形柱图)
            // label: {
            //   // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
            //   show: true, //是否显示标签
            //   position: 'insideTop', // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
            //   color: '#01E4FF',
            //   fontSize: 14,
            // },
            symbolSize: [barWidth,barWidth/2.5], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [0, barWidth/5], // 图形相对于原本位置的偏移
            z: 30, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: {
              color:'#0B99F6'
              // // 图形样式
              // // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   // 这里 offset: 0 1 ,表示从下往上的渐变色
              //   {
              //     offset: 0, // 0%处的颜色
              //     color: 'rgba(31,155,255,1)',
              //   },
              //   {
              //     offset: 1, // 100%处的颜色
              //     color: 'rgba(0,229,255,1)',
              //   },
              // ]),
            },
            data: yData,
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: 'bar', // 柱状图
            barWidth: barWidth, // 柱条的宽度,不设时自适应
            barGap: '0%', // 柱子与柱子之间的距离
            itemStyle: {
              // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: { x: 0, y: 0, x2: 0, y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0, // 0%处的颜色
                    color: '#265193',
                  },
                  {
                    offset: 1, // 100%处的颜色
                    color: '#03B1FA',
                  },
                ],
              },
            },
            data: yData,
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            type: 'pictorialBar',
            symbolSize: [barWidth, barWidth/2],
            symbolOffset: [0, -barWidth/3],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color:'#229EE0',
            },
            data: yData,
          },
        ],
      }
      return option
    },
    option3() {
      let seriesData = this.option3Data
      // [
      //   { value: 148, name: '残疾' },
      //   { value: 735, name: '轻度' },
      //   { value: 580, name: '中度' },
      //   { value: 484, name: '重度' },
      //   { value: 300, name: '活力' },
      // ]
      let total = seriesData.reduce((a, b) => {
        return a + b.value * 1
      }, 0)
      let option = {
        color: ['#5D8BD6', '#6C5985', '#DD6A42', '#EFB43C', '#5A785A'],

        tooltip: {
          ...this.$store.state.echart.tooltip,
          trigger: 'item',
          position:'right',
          formatter: function (params) {
            let percent = params.percent == undefined ? 0 : params.percent
            var res = params.marker + params.name + '：' + percent + '%'
            return res
          },
        },
        legend: {
          orient: 'vertical',
          icon: 'circle',
          right: '10%',
          top: 'center',
          itemGap: this.$common.sizeAdjust(16),
          formatter: (name) => {
            const item = seriesData.filter((item) => item.name === name)[0]
            let percent =
              total == 0 ? 0 : ((item.value / total) * 100).toFixed(2)
            return `{a|${name}} {b| ${item.value}}`
          },
          textStyle: {
            // 添加
            padding: [0, 0, 0, 0],
            rich: {
              a: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                width: this.$common.sizeAdjust(100),
                color: '#fff',
              },
              b: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                color: '#fff ',
              },
            },
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            center: ['25%', '50%'],
            radius: ['30%', '50%'],
            label: {
              show: false,
            },
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      return option
    },
    option4() {
      let seriesData = this.option4Data
      // [
      //   { value: 148, name: '独自居住' },
      //   { value: 735, name: '只和老伴' },
      //   { value: 580, name: '和家人' },
      //   { value: 484, name: '其他' },
      // ]
      let total = seriesData.reduce((a, b) => {
        return a + b.value * 1
      }, 0)
      let option = {
        color: ['#FCA78A', '#DFAE48', '#83B697', '#F38C30'],

        tooltip: {
          ...this.$store.state.echart.tooltip,
          trigger: 'item',
          formatter: function (params) {
            let percent = params.percent == undefined ? 0 : params.percent
            var res = params.marker + params.name + '：' + percent + '%'
            return res
          },
        },
        legend: {
          orient: 'vertical',
          icon: 'circle',
          right: '8%',
          top: 'center',
          itemGap: this.$common.sizeAdjust(16),
          formatter: (name) => {
            const item = seriesData.filter((item) => item.name === name)[0]
            let percent =
              total == 0 ? 0 : ((item.value / total) * 100).toFixed(2)
            return `{a|${name}} {b| ${item.value}}`
          },
          textStyle: {
            // 添加
            padding: [0, 0, 0, 0],
            rich: {
              a: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                width: this.$common.sizeAdjust(100),
                color: '#fff',
              },
              b: {
                fontSize: this.$common.sizeAdjust(2.4) + 'rem',
                color: '#fff ',
              },
            },
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            center: ['25%', '50%'],
            radius: ['30%', '50%'],
            label: {
              show: false,
            },
            data: seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
      return option
    },
    option5(){
      let option = {
        color: ['#01FAFF'],
        title: {
          text: '经济来源',
          left: '1%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },
        tooltip: {
          trigger: 'item',
          position:'top',
        },
        radar: {
          shape: 'polygon', // 雷达图形状，可以是'circle'（圆形）或'polygon'（多边形）
          center: ['50%', '50%'], // 雷达图中心点相对容器的位置，这里是居中显示
          radius: '55%', // 雷达图半径相对于容器大小的比例
          axisName: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#01FAFF00'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#01FAFF'
            }
          },


          // indicator: [
          //   { name: '个人储蓄', max: 50000 },
          //   { name: '其他', max: 50000 },
          //   { name: '投资收益', max: 50000 },
          //   { name: '养老保险', max: 50000 },
          //   { name: '退休后第二职业', max: 50000 },
          //   { name: '政府补贴', max: 50000 },
          //   { name: '子女供给', max: 50000 },
          //   { name: '退休金', max: 50000 },
          // ],
          indicator: this.option5Data.indicator
          
          
        },
        series: [
          {
            name: '经济情况分析',
            type: 'radar',
            symbol: 'none', 
            data: [
              {
                // value: [35000, 35000, 35000, 35000, 35000],
                value: this.option5Data.seriesdata,
                name: '经济来源',
                areaStyle: {
                  color: { x: 0, y: 0, x2: 0, y2: 1,
                    type: 'linear',
                    global: false,
                    colorStops: [
                      {
                        offset: 0, // 0%处的颜色
                        color: '#FFDAB57B',
                      },
                      {
                        offset: 1, // 100%处的颜色
                        color: '#04FFC1',
                      },
                    ],
                  },

                }


              },
            ]
          }
        ]


      };
      return option

    },
    option6(){
      let option = {
        color: ['#DA7BB5'],
        title: {
          text: '收入占比',
          left: '1%', 
          top: 'top', 
          textStyle: {
            color: '#4DEAEE', 
            fontSize: this.$common.sizeAdjust(25),
          },
        },
        tooltip: {
          trigger: 'item',
        },


        radar: {
          shape: 'polygon', // 雷达图形状，可以是'circle'（圆形）或'polygon'（多边形）
          center: ['50%', '50%'], // 雷达图中心点相对容器的位置，这里是居中显示
          radius: '55%', // 雷达图半径相对于容器大小的比例
          axisName: {
            color: '#fff'
          },
          axisLine: {
            lineStyle: {
              color: '#01FAFF00'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#01FAFF'
            }
          },

          // indicator: [
          //   { name: '1000元及以下', max: 50 },
          //   { name: '8001元及以上', max: 50 },
          //   { name: '7001至\n8000元', max: 50 },
          //   { name: '6001至\n7000元', max: 50 },
          //   { name: '5001至\n6000元', max: 50 },
          //   { name: '4001至\n5000元', max: 50 },
          //   { name: '3001至\n4000元', max: 50 },
          //   { name: '2001至\n3000元', max: 50 },
          //   { name: '1001至\n2000元', max: 50 },
          // ],
          indicator: this.option6Data.indicator

        },
        series: [
          {
            name: '经济情况分析',
            type: 'radar',
            symbol: 'none', 

            
            data: [
              {
                name: '收入占比',
                // value: [35000, 35000, 35000, 35000, 35000],
                value: this.option6Data.seriesdata,
           
                areaStyle: {


                  color: { x: 0, y: 0, x2: 0, y2: 1,
                    type: 'linear',
                    global: false,
                    colorStops: [
                      {
                        offset: 0, // 0%处的颜色
                        color: '#959EFB',
                      },
                      {
                        offset: 1, // 100%处的颜色
                        color: '#DA7BB5',
                      },
                    ],
                  },

                }


              },
            ]
          }
        ]


      };
      return option

    },
  },
  mounted() {
    this.getData()
  },
  //是当 keepalive 包含的组件再次渲染的时候触发
  activated() {},
  methods: {
    getData(){
      
      nlxbfx({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records
        // 将原始数据转换为ECharts柱状图所需的数据格式
        let yAxisdata = _data.map(item => item.old)
        yAxisdata = [...new Set(yAxisdata)]

        let seriesObj = {}
        for (const key in yAxisdata) {
          seriesObj[yAxisdata[key]] ={ 
            man:0,
            girl:0,
          }
        }
        for (const iterator of _data) {
          if (iterator.sex == "男") {
            seriesObj[iterator.old].man=iterator.num
          } else {
            seriesObj[iterator.old].girl=iterator.num
          }
        }
        yAxisdata = []
        let series1Data = []
        let series2Data = []
        for (const key in seriesObj) {
          yAxisdata.push(key)
          series1Data.push(seriesObj[key].man=-seriesObj[key].man)
          series2Data.push(seriesObj[key].girl)
        }
        let max  = Math.max(...series1Data, ...series2Data)

        //求和 series1Data 和 series2Data 计算两个的比例
        let manSum = series1Data.reduce((a, b) => a + b, 0)
        let girlSum = series2Data.reduce((a, b) => a + b, 0)
        //取 manSum 绝对值
        manSum = Math.abs(manSum)
        //计算 manSum 和 girlSum 占比 保留两位小数 乘100
        let manSumPercent = ((manSum / (manSum + girlSum))).toFixed(2) * 100
        let girlSumPercent = (girlSum / (manSum + girlSum)).toFixed(2) * 100

        this.option1Data={
          yAxisdata,
          series1Data,
          series2Data,
          max,
          manSumPercent,
          girlSumPercent,
        }
      })

      snfx({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records
        for (const iterator of _data) {
          iterator.value = iterator.num
          iterator.name =   iterator.disability
          
        }
        this.option3Data = _data
      })
      jzqkfx({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records
        for (const iterator of _data) {
          iterator.value = iterator.num
          iterator.name =   iterator.live.replace('一起住','')
          
        }
        this.option4Data = _data
      })

      mbfx({
        params:{
          pageNum:1,
          pageSize:50,
          area:this.area,
        }
      }).then(res => {
        let _data = res.data.records
        let yAxisdata = []
        let seriesdata = []
        for (const iterator of _data) {
          iterator.ill = iterator.ill.replace('（阿尔兹海默症）','')
          yAxisdata.push(iterator.ill)
          seriesdata.push(iterator.num)
        }
        this.option2Data = {
          yAxisdata,
          seriesdata
        }
      })

      

      this.jjqkfx()

    },
    // 经济情况分析
    jjqkfx(){
      if (this.economics_activate =='收入') {
        jjqkfx_jjly({
          params:{
            pageNum:1,
            pageSize:50,
            area:this.area,
          }
        }).then(res => {
          let _data = res.data.records
          let seriesdata = []
          seriesdata = _data.map(item => item.num)
          //取 seriesdata 最大值
          let max = Math.max.apply(null, seriesdata)
          max = max * 1.2
          let indicator = []
          indicator = _data.map(item => {
            return {
              name: item.financial.replace('第','\n第'),
              max: max,
            }
          })
          this.option5Data={
            indicator,seriesdata
          }
        })
        //占比
        jjqkfx_srzb({
          params:{
            pageNum:1,
            pageSize:50,
            area:this.area,
          }
        }).then(res => {
          let _data = res.data.records
          let seriesdata = []
          seriesdata = _data.map(item => item.num)
          //取 seriesdata 最大值
          let max = Math.max.apply(null, seriesdata)
          max = max * 1.2
          let indicator = []
          indicator = _data.map(item => {
            return {
              name: item.income_range.replace('-','至\n'),
              max: max,
            }
          })
          this.option6Data={
            indicator,seriesdata,max
          }
        })
      }else{
        jjqkfx_zcxm({
          params:{
            pageNum:1,
            pageSize:50,
            area:this.area,
          }
        }).then(res => {
          let _data = res.data.records
          let seriesdata = []
          seriesdata = _data.map(item => item.num)
          //取 seriesdata 最大值
          let max = Math.max.apply(null, seriesdata)
          max = max * 1.2
          let indicator = []
          indicator = _data.map(item => {
            return {
              name: item.output_items,
              max: max,
            }
          })
          this.option5Data={
            indicator,seriesdata
          }
        })
        //占比
        jjqkfx_zczb({
          params:{
            pageNum:1,
            pageSize:50,
            area:this.area,
          }
        }).then(res => {
          let _data = res.data.records
          let seriesdata = []
          seriesdata = _data.map(item => item.num)
          //取 seriesdata 最大值
          let max = Math.max.apply(null, seriesdata)
          max = max * 1.2
          let indicator = []
          indicator = _data.map(item => {
            return {
              name: item.output_range.replace('-','至\n').replace('及','\n及'),
              max: max,
            }
          })
          this.option6Data={
            indicator,seriesdata,max
          }
        })





      }
    },

  },
}
</script>
<style scoped lang="scss">
.map-left {
  width: 28%;
  overflow: hidden;
  .imgbg{
    position: absolute;
    height: 3rem;
    width: 90%;
    left: 4rem;
    bottom: 3.8rem
  }


  .imgbox{

    position: relative;
    width: 3.8rem;
    height: 3.8rem;
   
    img{
      width: 3.8rem;
      height: 3.8rem;
    }

    .filler{
      position: absolute;
      bottom: 0;
      top: 0;
      overflow: hidden;
    }
  }
}
</style>